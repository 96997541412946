body {
	--app-color: #d0b6fb;
	--app-background: #dfdfdf;
	--app-highlight: #d0b6fb1e;
	--app-text: #000000;
	--app-maincolor: #ffffff;
}

/* removes hyperlink unstyling */
a {
	text-decoration: none;
	color: var(--app-text);
}

.app {
	display: flex;
	height: 100vh;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 10px;
	background-color: var(--app-maincolor);
}
