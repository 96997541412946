.messages {
	flex: 0.4;
	border-right: 1px solid var(--app-background);
	overflow-y: scroll;
	min-width: 300px;
}

.messages__newChat {
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: 91px;
	border-bottom: 1px solid var(--app-background);
}

.messages__newChat:hover {
	background-color: var(--app-highlight);
}

.messages__header {
	position: sticky;
	top: 0;
	z-index: 100;
	background-color: #fff;
	border-bottom: 1px solid var(--app-background);
	padding: 15px 20px;
}

.messages__header > h2 {
	font-size: 20px;
	font-weight: 800;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messages::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.messages {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
