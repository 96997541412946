.login {
	display: flex;
	flex-direction: column;
	place-items: center;
	height: 100vh;
	width: 100%;
	padding: 20px;
}

.login > button {
	width: 250px;
	background-color: black;
	color: white;
	font-weight: 800;
	margin-top: 40px;
}

.login > button:hover {
	background-color: gray;
	color: black;
}

.login > div > img {
	object-fit: contain;
	height: 150px;
	margin-top: 140px;
}
