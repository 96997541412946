.container {
	display: flex;
	padding: 8px;
}

.dm__userAvatar {
	display: none !important;
}

.dm__guestAvatar {
	margin-right: 8px;
	margin-top: auto;
	background: none;
}

.dm {
	overflow: auto;
	max-width: 65%;
	hyphens: auto;
	height: fit-content;
	display: flex;
	font-weight: 500;
	white-space: pre-wrap;
}

.dm__userStyle {
	margin-left: auto;
	color: black;
}

.dm__guestStyle {
	margin-right: auto;
}

.dm__userText {
	padding: 15px;
	border-radius: 20px;
	background-color: var(--app-color);
	align-items: center;
	word-break: break-word;
	color: white;
}

.dm__guestText {
	padding: 15px;
	border-radius: 20px;
	background-color: lightgray;
	align-items: center;
	word-break: break-word;
}
