.post {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid var(--app-background);
	padding-bottom: 5px;
	padding-right: 5px;
	min-width: fit-content;
	cursor: pointer;
	z-index: 101;
}

.post__body {
	flex: 1;
	padding: 10px 20px 10px 10px;
	word-break: break-word;
	white-space: pre-wrap;
}

.post__body > img {
	border-radius: 20px;
	max-height: 1080px;
	max-width: 100%;
	min-height: 100%;
	min-width: 100%;
	margin-bottom: 10px;
}

.post__footer {
	display: flex;
	justify-content: space-between;
	margin-top: 3px;
}

.post__headerDescription {
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 15px;
}

.post__headerText > h3 {
	font-size: 15px;
	margin-bottom: 5px;
}

.post__badge {
	font-size: 14px !important;
	color: var(--app-color);
}

.post__headerSpecial {
	flex: 1;
	font-weight: 600;
	font-size: 12px;
	color: gray;
}

.post__avatar {
	padding: 20px;
}

.post:hover {
	background-color: var(--app-highlight);
	border-radius: 0px;
	color: var(--app-color);
	transition: color 100ms ease-out;
	border-bottom: 1px solid var(--app-color);
}

.post__timeStamp {
	float: right;
	color: lightgray;
	text-align: right;
	padding-left: 10px;
	padding-right: 5px;
	font-size: 10px;
}

.icon__like:hover {
	color: red;
}

.icon__comment:hover {
	color: blue;
}

.icon__repeat:hover {
	color: green;
}

.icon__share:hover {
	color: blue;
}

.post .MuiIconButton-root {
	background-color: white;
	box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1) !important;
}
