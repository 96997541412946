.sidebar {
	border-right: 1px solid var(--app-background);
	flex: 0.2;
	min-width: 55px;
	margin-top: 00px;
	padding-top: 20px;
	padding-left: 0px;
	padding-right: 0px;
}

.logout {
	font-size: 25px !important;
	cursor: pointer !important;
	margin-left: 20px !important;
	margin-top: 20px;
}

.logout:hover {
	color: gray !important;
}
