.chatScreen {
	flex: 0.4;
	border-right: 1px solid var(--app-background);
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chatScreen::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chatScreen {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	min-width: 300px;
}

.chatScreen__header {
	display: flex;
	flex-direction: row;
	align-content: center !important;
	position: sticky !important;
	height: 54px;
	top: 0;
	z-index: 100;
	border-bottom: 1px solid var(--app-background);
	background-color: var(--app-maincolor) !important;
}

.chatScreen__back {
	position: sticky;
	margin-left: 5px !important;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.chatScreen__greeting {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.chatScreen__header > h2 {
	font-size: 20px;
	font-weight: 800;
}

.chatScreen__form {
	margin-top: auto;
	background-color: var(--app-maincolor) !important;
	padding: 5px;
	position: sticky !important;
	bottom: 0 !important;
	width: 100%;
	z-index: 200;
	border-top: 1px solid var(--app-background);
	height: 55px;
}

.chatScreen__input {
	display: flex !important;
	flex-direction: row !important;
	margin-left: 5px !important;
}

.chatScreen__inputField {
	margin-bottom: 5px !important;
	flex: 1;
}

.chatScreen__inputButton {
	flex: 0;
	margin-left: 10px !important;
}

.MuiIconButton-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}

.MuiIconButton-colorPrimary {
	color: var(--app-color) !important;
}
