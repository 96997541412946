.chat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-left: 20px;
    height: 70px;
    border-bottom: 1px solid var(--app-background);
    overflow: auto;
    word-break: break-word;
  }

.chat:hover {
    background-color: var(--app-highlight);
    border-radius: 0px;
    color: var(--app-color);
    transition: color 100ms ease-out;
    border-bottom: 1px solid var(--app-color);
    cursor: pointer;
}

.chat__details {
    flex: 1;
    padding-left: 20px;
}

.chat__details > h2 {
    font-size: 18px;
}

.chat__details > p {
    padding-top: 5px;
    font-size: 12px;
    color: gray;
}

.chat__timestamp {
    margin-left: 5px;
    color: lightgray;
    font-size: 15px;
}