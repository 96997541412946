.feed {
	flex: 0.5;
	border-right: 1px solid var(--app-background);
	overflow-y: scroll;
	min-width: 320px;
	z-index: 101;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.feed::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.feed {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.feed__header {
	position: sticky;
	top: 0;
	z-index: 100;
	border-bottom: 1px solid var(--app-background);
	padding: 15px 20px;
	background-color: var(--app-maincolor);
}

.feed__header > h2 {
	font-size: 20px;
	font-weight: 800;
}
