.postBox {
	display: flex;
	flex-direction: row;
	padding: 10px 0px;
	border-bottom: 7px solid var(--app-background);
	z-index: 99;
}

.postBox__avatar {
	padding: 20px;
}

/* Avatar */
.MuiAvatar-root {
	width: 48px !important;
	height: 48px !important;
	cursor: pointer !important;
}

.postBox__form {
	flex: 1;
}

.postBox__inputField {
	display: flex;
	flex-direction: column;
	margin: 10px 30px 20px 20px;
}

.postBox__image {
	cursor: pointer;
	border-radius: 20px;
	max-height: 1080px;
	max-width: 100%;
	min-height: 100%;
	min-width: 100%;
	margin-bottom: 10px;
}

.postBox__cancelImage {
	position: absolute !important;
	margin: 7px !important;
}

.MuiFormControl-root {
	flex: 1;
	margin-bottom: 20px !important;
}

.MuiInput-underline:after {
	border-bottom-color: var(--app-color) !important;
}

.MuiFormLabel-root.Mui-focused {
	color: var(--app-color) !important;
}

.postBox__bottomBar {
	display: flex;
	justify-content: flex-end !important;
	margin: 0px 20px 12px 0px;
}

.postBox__image {
	max-width: 100%;
	max-height: 100%;
}

.postBox__postButton {
	display: flex;
	background-color: var(--app-color) !important;
	border: none !important;
	font-weight: 900 !important;
	text-transform: inherit !important;
	border-radius: 30px !important;
	width: 80px !important;
	height: 40px !important;
}
